import styled from 'styled-components';
import Img from 'gatsby-image';

export const FeaturedImg = styled(Img)`
  height: 80vh;

  @media (min-width: 768px) {
    height: 80vh;
  }
`;

export const FeaturedAlbumSection = styled.section`
  background-color: ${({ theme }) => theme.colors.sapphireBlue};
  padding: 24px 0;
  color: ${({ theme }) => theme.colors.white};

  @media (min-width: 768px) {
    padding: 48px 0;
  }

  h2 {
    margin-bottom: 16px;
    text-align: center;

    @media (min-width: 768px) {
      text-align: left;
      margin-bottom: 0;
    }
  }

  p {
    margin-top: 16px;
  }

  .buttonContainer {
    display: flex;
  }

  a {
    margin: 24px auto 0;
    text-align: center;
    text-decoration: none;

    @media (min-width: 768px) {
      display: inline-block;
      margin: 24px 0 0;
      align-self: flex-end;
    }
  }
`;

export const GigsSection = styled.section`
  padding: 24px 0;
  color: ${({ theme }) => theme.colors.sapphireBlue};

  @media (min-width: 768px) {
    padding: 48px 0;
  }

  h2 {
    margin-bottom: 24px;
    text-align: center;
  }

  .upcoming-message {
    width: 80%;
    margin: 0 auto 35px auto;
    text-align: center;
    @media (max-width: 767px) {
      line-height: 1.6;
    }
  }

  ul {
    max-width: 300px;
    margin: 0 auto;
    text-align: center;
    list-style-type: none;
  }

  li {
    margin-bottom: 80px;
    position: relative;

    p {
      margin-bottom: 8px;
    }

    &:not(:first-child) {
      div::after {
        content: '';
        display: block;
        width: 50px;
        height: 2px;
        margin: 0 auto;
        background-color: ${({ theme }) => theme.colors.sapphireBlue};
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 125%;
      }
    }
  }

  li:last-of-type {
    margin-bottom: 50px;
  }
  
  h3 {
    margin-bottom: 8px;
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
  }
`;
