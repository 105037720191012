import React, { useState, useEffect } from "react";
import { graphql } from "gatsby"
import Img from 'gatsby-image';
import parse from 'html-react-parser';
import SEO from "../components/seo";
import { useMediaQuery } from 'react-responsive';
import { StyledLink } from '../components/Button/ButtonsStyles'

import { renderGigs } from '../utils/utils';
import { fetchGigs } from '../utils/api';

import { Container, Row, Col } from 'react-bootstrap';

import { FeaturedImg, FeaturedAlbumSection, GigsSection } from '../styles/indexStyles';

import Layout from "../components/layout";

const IndexPage = ( { data } ) => {
  const albumTitle = data.contentfulFeaturedAlbum.albumTitle;
  const albumDescription = data.contentfulFeaturedAlbum.albumDescription.childMarkdownRemark.html;
  const albumImg = data.contentfulFeaturedAlbum.albumImage.fluid;
  const albumUrl = data.contentfulFeaturedAlbum.albumUrl;

  const isTabletOrMobile = useMediaQuery({ maxWidth: 767 });

  const renderFeaturedAlbumSection = () => {

    if ( isTabletOrMobile ) {
      return (
        <Row>
          <Col>
            <h2>{albumTitle}</h2>
            <Img fluid={albumImg} alt={albumTitle} />
            { parse(albumDescription) }
            <div className="buttonContainer">
              <StyledLink to={albumUrl}>Listen Now</StyledLink>
            </div>
          </Col>
        </Row>
      )
    } else {
      return (
        <Row>
          <Col md={4}>
            <Img fluid={albumImg} alt={albumTitle} />
          </Col>
          <Col md={8} className="d-md-flex flex-md-column justify-content-md-between">
            <h2>{albumTitle}</h2>
            <div>
              { parse(albumDescription) }
            </div>
            <StyledLink to="/albums/sky-glow">Listen Now</StyledLink>
          </Col>
        </Row>
      )
    }
  }

  const [upcomingGigs, setUpcomingGigs] = useState([]);

  useEffect(() => {
    fetchGigs()
      .then(resultData => {
        // Get Eastern time
        const torontoDateToday = new Date().toLocaleString('en-US', { timeZone: "America/New_York", year: 'numeric', month: 'numeric', day: 'numeric' });

        const convertToISODate = (date) => {
          return new Date(date).toISOString().split('T')[0]
        }

        const futureGigs = resultData.data.items
          .filter(item => (item.fields.gigDate) >= convertToISODate(torontoDateToday))
          .sort((a, b) => new Date(a.fields.gigDate) - new Date(b.fields.gigDate));

        setUpcomingGigs(futureGigs);
      })
  }, [])

  return(
    <Layout>
      <SEO title="Home" />
      <FeaturedImg fluid={data.contentfulHomePage.desktopCoverImage.fluid} alt="Chris Platt Hero Image" />
      <FeaturedAlbumSection>
        <Container>
          { renderFeaturedAlbumSection() }
        </Container>
      </FeaturedAlbumSection>
      <GigsSection>
        <Container>
          <h2>Upcoming Gigs</h2>
            {renderGigs( upcomingGigs )}
          <div className="buttonContainer">
            <StyledLink to={'/gigs'}>View More</StyledLink>
          </div>
        </Container>
      </GigsSection>
    </Layout>
  )
}

export default IndexPage;

export const pageQuery = graphql`
  query homePageQuery {
    contentfulFeaturedAlbum {
      albumTitle
      albumDescription {
        childMarkdownRemark {
          html
        }
      }
      albumImage {
        fluid(maxWidth: 600, quality: 100) {
          base64
          aspectRatio
          src
          srcSet
          sizes
        }
      }
      albumUrl
    }
     contentfulHomePage {
      desktopCoverImage {
        fluid(maxWidth: 1500, quality: 100) {
          aspectRatio
          base64
          sizes
          src
          srcSet
        }
      }
    }
  }
`
